html {
  margin: 0;
  padding: 0;
  font-family: "Inter Tight";
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  background: #fdfbff;
  color: #171717;
  font-family: "Inter Tight";
}
