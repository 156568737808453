/* DiplomaPage.module.css */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  text-align: center;
  background-color: white;
  color: #000;
}

.header {
  border-bottom: solid 1px rgb(222, 222, 222);
  width: 100%;
  padding: 1rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 8.44325rem;
  height: 2.5rem;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: calc(100vh - 4.5rem);
}

.leftColumn {
  display: flex;
  width: 60%;
  justify-content: flex-end;
}

.rightColumn {
  display: flex;
  width: 40%;
}

.circlesInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 25rem;
  align-items: flex-start;
  gap: 1.5rem;
}

.circlesTagline {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.375rem;
}

.circlesDescription {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
}

.button {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background-color: #2e82ff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s,
    box-shadow 0.3s;
  border: none;
}

.button:hover {
  background-color: #0750a9;
  color: #ffffff;
}

.button:active {
  background-color: #d0d0d0;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.button:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
}

.certificateImg {
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.2);
  width: 100%;
  height: auto;
  max-width: 46rem;
}

.spinnerSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 5rem);
}

.customSpinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-top-color: #007bff; /* Replace with your desired spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .leftColumn {
    width: 100%;
  }
  
  .rightColumn {
    width: 100%;
  }

  .content {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .circlesInfo {
    max-width: 100%;
    padding: 0rem;
    margin: 0rem;
  }

}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
