/* DiplomaPage.module.css */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: white;
  color: #000;
}

.header {
  border-bottom: solid 1px rgb(222, 222, 222);
  width: 100%;
  padding: 1rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 8.44325rem;
  height: 2.5rem;
}

.certificateImg {
  width: 5rem;
  height: 5rem;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
  flex-direction: column;
}

.leftColumn {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

.rightColumn {
  background-color: #f5f5f5;
  padding: 2rem 8rem;
  align-items: center;
}

.circlesInfo {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  align-items: center;
  gap: 1.5rem;
}

.noCertificateTagline {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

.circlesTagline {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.375rem;
}

.circlesDescription {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
}

.button {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background-color: #2e82ff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s,
    box-shadow 0.3s;
  border: none;
}

.button:hover {
  background-color: #0750a9;
  color: #ffffff;
}

.button:active {
  background-color: #d0d0d0;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.button:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .content {
    gap: 3rem;
  }

  .rightColumn {
    padding: 2rem 3rem;
  }
}
